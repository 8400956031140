define('votespotter-nxtgen/app', ['exports', 'ember', 'votespotter-nxtgen/resolver', 'ember-load-initializers', 'votespotter-nxtgen/config/environment'], function (exports, _ember, _votespotterNxtgenResolver, _emberLoadInitializers, _votespotterNxtgenConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _votespotterNxtgenConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _votespotterNxtgenConfigEnvironment['default'].podModulePrefix,
    Resolver: _votespotterNxtgenResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _votespotterNxtgenConfigEnvironment['default'].modulePrefix);

  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") !== -1) {
    OSName = "Windows";
  }
  if (navigator.appVersion.indexOf("Mac") !== -1) {
    OSName = "MacOS";
  }
  if (navigator.appVersion.indexOf("X11") !== -1) {
    OSName = "UNIX";
  }
  if (navigator.appVersion.indexOf("Linux") !== -1) {
    OSName = "Linux";
  }
  console.log('Your OS: ' + OSName);
  _ember['default'].$('body').addClass(OSName);

  if (_votespotterNxtgenConfigEnvironment['default'].environment === 'production') {
    console.log('Production Build version:', _votespotterNxtgenConfigEnvironment['default'].APP.version);
    console.log('Disable the console.log...');

    // Disable console.log
    if (!window.console) {
      window.console = {};
    }
    var methods = ["log", "debug", "warn", "info"];
    for (var i = 0; i < methods.length; i++) {
      console[methods[i]] = function () {};
    }
  }

  exports['default'] = App;
});