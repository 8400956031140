define("votespotter-nxtgen/pods/components/pagecomp-legislator-detail/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "votespotter-nxtgen/pods/components/pagecomp-legislator-detail/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" START pagecomp-legislator-detail comp ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "leg-detail-section");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "votes-section");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "comparing-section");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" END pagecomp-legislator-detail comp ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "legislator-profile", [], ["user", ["subexpr", "readonly", [["get", "attrs.user", ["loc", [null, [21, 38], [21, 48]]]]], [], ["loc", [null, [21, 28], [21, 49]]]], "legislator", ["subexpr", "readonly", [["get", "attrs.legislator", ["loc", [null, [21, 71], [21, 87]]]]], [], ["loc", [null, [21, 61], [21, 88]]]]], ["loc", [null, [21, 2], [21, 90]]]], ["inline", "legislator-bills", [], ["user", ["subexpr", "readonly", [["get", "attrs.user", ["loc", [null, [25, 36], [25, 46]]]]], [], ["loc", [null, [25, 26], [25, 47]]]], "legislator", ["subexpr", "readonly", [["get", "attrs.legislator", ["loc", [null, [25, 69], [25, 85]]]]], [], ["loc", [null, [25, 59], [25, 86]]]]], ["loc", [null, [25, 2], [25, 88]]]], ["inline", "legislator-votes", [], ["user", ["subexpr", "readonly", [["get", "attrs.user", ["loc", [null, [29, 36], [29, 46]]]]], [], ["loc", [null, [29, 26], [29, 47]]]], "legislator", ["subexpr", "readonly", [["get", "attrs.legislator", ["loc", [null, [29, 69], [29, 85]]]]], [], ["loc", [null, [29, 59], [29, 86]]]]], ["loc", [null, [29, 2], [29, 88]]]]],
      locals: [],
      templates: []
    };
  })());
});