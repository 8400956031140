define("votespotter-nxtgen/pods/components/pagecomp-bill-detail/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 10
            },
            "end": {
              "line": 31,
              "column": 10
            }
          },
          "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          | ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["content", "attrs.bill.status", ["loc", [null, [30, 18], [30, 39]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 12
                },
                "end": {
                  "line": 69,
                  "column": 12
                }
              },
              "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("li");
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("a");
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("strong");
              var el4 = dom.createElement("img");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("span");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1]);
              var element6 = dom.childAt(element5, [1]);
              var element7 = dom.childAt(element6, [1, 0]);
              var morphs = new Array(5);
              morphs[0] = dom.createElementMorph(element5);
              morphs[1] = dom.createAttrMorph(element6, 'class');
              morphs[2] = dom.createAttrMorph(element7, 'src');
              morphs[3] = dom.createAttrMorph(element7, 'alt');
              morphs[4] = dom.createMorphAt(dom.childAt(element6, [3]), 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["transitionHandler", "legislatorDetail", ["get", "leg.id", ["loc", [null, [63, 64], [63, 70]]]]], [], ["loc", [null, [63, 16], [63, 72]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "leg.agree", ["loc", [null, [64, 33], [64, 42]]]], true], [], ["loc", [null, [64, 29], [64, 48]]]], "color-1", "color-2"], [], ["loc", [null, [64, 24], [64, 71]]]]]]], ["attribute", "src", ["concat", [["get", "leg.avatarUrl", ["loc", [null, [65, 36], [65, 49]]]]]]], ["attribute", "alt", ["concat", [["get", "leg.name", ["loc", [null, [65, 60], [65, 68]]]]]]], ["content", "leg.name", ["loc", [null, [66, 22], [66, 34]]]]],
            locals: ["leg"],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 8
              },
              "end": {
                "line": 73,
                "column": 8
              }
            },
            "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "my-leg-con-area");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#selectConMyLeg");
            dom.setAttribute(el2, "class", "conMyLeg-btn");
            var el3 = dom.createElement("span");
            var el4 = dom.createTextNode("Contact my legislator");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "my-leg-con-section");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "my-leg-con-box");
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4, "class", "my-leg-email-btn");
            var el5 = dom.createTextNode("\n                ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("span");
            var el6 = dom.createTextNode("Email");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("i");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n              ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("a");
            dom.setAttribute(el4, "class", "my-leg-call-btn");
            var el5 = dom.createElement("span");
            var el6 = dom.createTextNode("Call");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "href", "#");
            dom.setAttribute(el3, "class", "can-my-leg-con-btn");
            var el4 = dom.createTextNode("Cancel");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "leg-think-section");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h3");
            var el3 = dom.createTextNode("WHAT MY LEGISLATORS THINK");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            dom.setAttribute(el2, "class", "leg-list");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element8 = dom.childAt(fragment, [1, 3, 1]);
            var element9 = dom.childAt(element8, [1]);
            var element10 = dom.childAt(element8, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createElementMorph(element9);
            morphs[1] = dom.createMorphAt(dom.childAt(element9, [2]), 0, 0);
            morphs[2] = dom.createElementMorph(element10);
            morphs[3] = dom.createMorphAt(dom.childAt(fragment, [5, 3]), 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["contactLegislator", ["get", "selectedLegislator", ["loc", [null, [50, 46], [50, 64]]]]], [], ["loc", [null, [50, 17], [50, 66]]]], ["content", "selectedLegislator.email", ["loc", [null, [51, 37], [51, 65]]]], ["element", "action", ["phoneCallPopup", ["get", "legislator", ["loc", [null, [53, 43], [53, 53]]]]], [], ["loc", [null, [53, 17], [53, 55]]]], ["block", "each", [["get", "whatLegislatorThink", ["loc", [null, [62, 20], [62, 39]]]]], [], 0, null, ["loc", [null, [62, 12], [69, 21]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 6
            },
            "end": {
              "line": 74,
              "column": 6
            }
          },
          "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "liquid-if", [["get", "isShowMyLegislatorInfo", ["loc", [null, [45, 21], [45, 43]]]]], ["class", "is-show-my-legislator-info"], 0, null, ["loc", [null, [45, 8], [73, 22]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 76,
              "column": 6
            },
            "end": {
              "line": 79,
              "column": 6
            }
          },
          "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "legislator-msg-send", [], ["legislator", ["subexpr", "readonly", [["get", "selectedLegislator", ["loc", [null, [77, 49], [77, 67]]]]], [], ["loc", [null, [77, 39], [77, 68]]]], "updateIsShowingLegislatorContactForm", ["subexpr", "action", [["subexpr", "mut", [["get", "isShowingLegislatorContactForm", ["loc", [null, [78, 78], [78, 108]]]]], [], ["loc", [null, [78, 73], [78, 109]]]]], [], ["loc", [null, [78, 65], [78, 110]]]]], ["loc", [null, [77, 6], [78, 112]]]]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 96,
                "column": 4
              },
              "end": {
                "line": 103,
                "column": 4
              }
            },
            "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("a");
            dom.setAttribute(el2, "href", "#leg-1");
            dom.setAttribute(el2, "class", "my-leg");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1, 1]);
            var element4 = dom.childAt(element3, [1]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element3, 'data-email');
            morphs[1] = dom.createAttrMorph(element3, 'data-phone');
            morphs[2] = dom.createAttrMorph(element3, 'data-firstname');
            morphs[3] = dom.createElementMorph(element3);
            morphs[4] = dom.createAttrMorph(element4, 'src');
            morphs[5] = dom.createAttrMorph(element4, 'alt');
            morphs[6] = dom.createMorphAt(dom.childAt(element3, [3]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "data-email", ["concat", [["get", "item.email", ["loc", [null, [98, 89], [98, 99]]]]]]], ["attribute", "data-phone", ["concat", [["get", "item.phoneNumber", ["loc", [null, [98, 117], [98, 133]]]]]]], ["attribute", "data-firstname", ["concat", [["get", "item.firstName", ["loc", [null, [98, 155], [98, 169]]]]]]], ["element", "action", ["selectedLegislator", ["get", "item", ["loc", [null, [98, 68], [98, 72]]]]], [], ["loc", [null, [98, 38], [98, 74]]]], ["attribute", "src", ["concat", [["get", "item.avatarUrl", ["loc", [null, [99, 20], [99, 34]]]]]]], ["attribute", "alt", ["concat", [["get", "item.name", ["loc", [null, [99, 45], [99, 54]]]]]]], ["content", "item.name", ["loc", [null, [100, 14], [100, 27]]]]],
          locals: ["item"],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 93,
              "column": 0
            },
            "end": {
              "line": 107,
              "column": 0
            }
          },
          "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "modal-section modal-con-my-leg mfp-hide");
          dom.setAttribute(el1, "id", "selectConMyLeg");
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("ul");
          dom.setAttribute(el2, "class", "my-leg-list");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "title", "%title%");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "btn mfp-close");
          var el3 = dom.createTextNode("Cancel");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "whatLegislatorThink", ["loc", [null, [96, 12], [96, 31]]]]], [], 0, null, ["loc", [null, [96, 4], [103, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 110,
                "column": 2
              },
              "end": {
                "line": 129,
                "column": 2
              }
            },
            "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "col-xs-12 text-center center-block");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12 text-center center-block");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("span");
            dom.setAttribute(el3, "class", "title");
            var el4 = dom.createTextNode("Do you want to call ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("?");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12 text-center center-block");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-6 text-center center-block");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "type", "submit");
            dom.setAttribute(el3, "class", "btn btn-primary");
            var el4 = dom.createElement("span");
            var el5 = dom.createTextNode("Cancel");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-6 text-center center-block");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "class", "btn btn-primary my-leg-call-btn");
            var el4 = dom.createElement("span");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [5, 1]);
            var element2 = dom.childAt(element0, [7, 1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 1, 1);
            morphs[1] = dom.createElementMorph(element1);
            morphs[2] = dom.createAttrMorph(element2, 'href');
            morphs[3] = dom.createMorphAt(dom.childAt(element2, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "selectedLegFirstName", ["loc", [null, [117, 48], [117, 72]]]], ["element", "action", ["cancelCall"], [], ["loc", [null, [123, 30], [123, 53]]]], ["attribute", "href", ["concat", ["tel:", ["get", "selectedLegPhoneNumNormalized", ["loc", [null, [126, 23], [126, 52]]]]]]], ["content", "selectedLegPhoneNum", ["loc", [null, [126, 102], [126, 125]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 109,
              "column": 0
            },
            "end": {
              "line": 130,
              "column": 0
            }
          },
          "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], ["close", "closePhoneCallPopup", "targetAttachment", "center", "translucentOverlay", false, "container-class", "legislator-call-phone-container", "overlay-class", "legislator-call-phone-overlay"], 0, null, ["loc", [null, [110, 2], [129, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 132,
            "column": 38
          }
        },
        "moduleName": "votespotter-nxtgen/pods/components/pagecomp-bill-detail/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" START pagecomp-bill-detail comp ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "content");
        dom.setAttribute(el1, "class", "tab-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "main-content tab-pane active bills scr-tab-top");
        dom.setAttribute(el2, "role", "tabpanel");
        dom.setAttribute(el2, "id", "bills");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "bill-detail-area");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "vote-section share-box bg-img-bill");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "img-box");
        var el6 = dom.createTextNode("\n           \n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "title-box title-box-layover");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h4");
        var el7 = dom.createElement("i");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("h5");
        dom.setAttribute(el6, "class", "bill-title");
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "bill-detail-section container-tiny");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" END pagecomp-bill-detail comp ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [3, 1]);
        var element12 = dom.childAt(element11, [1, 1]);
        var element13 = dom.childAt(element12, [1]);
        var element14 = dom.childAt(element12, [3]);
        var element15 = dom.childAt(element14, [1]);
        var element16 = dom.childAt(element11, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element13, 'style');
        morphs[1] = dom.createMorphAt(dom.childAt(element15, [0]), 0, 0);
        morphs[2] = dom.createMorphAt(element15, 2, 2);
        morphs[3] = dom.createMorphAt(dom.childAt(element14, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(element12, 5, 5);
        morphs[5] = dom.createMorphAt(dom.childAt(element16, [1]), 1, 1);
        morphs[6] = dom.createMorphAt(element16, 3, 3);
        morphs[7] = dom.createMorphAt(element16, 5, 5);
        morphs[8] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        morphs[9] = dom.createMorphAt(fragment, 7, 7, contextualElement);
        return morphs;
      },
      statements: [["attribute", "style", ["subexpr", "html-safe-string", [["subexpr", "concat", ["background: url(", ["get", "attrs.bill.bgImageUrl", ["loc", [null, [24, 65], [24, 86]]]], ") center no-repeat; background-size:cover;"], [], ["loc", [null, [24, 38], [24, 132]]]]], [], ["loc", [null, [24, 19], [24, 134]]]]], ["content", "attrs.bill.billUniqueCode", ["loc", [null, [28, 17], [28, 46]]]], ["block", "if", [["get", "attrs.bill.status", ["loc", [null, [29, 16], [29, 33]]]]], [], 0, null, ["loc", [null, [29, 10], [31, 17]]]], ["content", "attrs.bill.title", ["loc", [null, [34, 10], [34, 30]]]], ["inline", "agree-disagree", [], ["user", ["subexpr", "readonly", [["get", "attrs.user", ["loc", [null, [37, 40], [37, 50]]]]], [], ["loc", [null, [37, 30], [37, 51]]]], "bill", ["subexpr", "readonly", [["get", "attrs.bill", ["loc", [null, [37, 67], [37, 77]]]]], [], ["loc", [null, [37, 57], [37, 78]]]]], ["loc", [null, [37, 8], [37, 80]]]], ["content", "attrs.bill.body", ["loc", [null, [42, 6], [42, 25]]]], ["block", "unless", [["get", "isAnonymousUser", ["loc", [null, [44, 16], [44, 31]]]]], [], 1, null, ["loc", [null, [44, 6], [74, 17]]]], ["block", "if", [["get", "isShowingLegislatorContactForm", ["loc", [null, [76, 12], [76, 42]]]]], [], 2, null, ["loc", [null, [76, 6], [79, 13]]]], ["block", "if", [["get", "isShowMyLegislatorInfo", ["loc", [null, [93, 6], [93, 28]]]]], [], 3, null, ["loc", [null, [93, 0], [107, 7]]]], ["block", "if", [["get", "isShowingPhoneCallPopup", ["loc", [null, [109, 6], [109, 29]]]]], [], 4, null, ["loc", [null, [109, 0], [130, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});