define("votespotter-nxtgen/pods/components/pagecomp-my-profile/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.3",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 0
              },
              "end": {
                "line": 54,
                "column": 0
              }
            },
            "moduleName": "votespotter-nxtgen/pods/components/pagecomp-my-profile/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "loader-container");
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "loader-ani-icon");
            var el3 = dom.createTextNode("\n    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "loader-text");
            var el3 = dom.createTextNode("\n  Loading...\n  ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
            return morphs;
          },
          statements: [["content", "mdl-spinner", ["loc", [null, [48, 4], [48, 19]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 44,
              "column": 0
            },
            "end": {
              "line": 55,
              "column": 0
            }
          },
          "moduleName": "votespotter-nxtgen/pods/components/pagecomp-my-profile/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "modal-dialog", [], [], 0, null, ["loc", [null, [45, 0], [54, 17]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 57,
            "column": 37
          }
        },
        "moduleName": "votespotter-nxtgen/pods/components/pagecomp-my-profile/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" START pagecomp-my-profile comp ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "content");
        dom.setAttribute(el1, "class", "tab-content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "profile-section tab-pane scr-tab-top active profile");
        dom.setAttribute(el2, "role", "tabpanel");
        dom.setAttribute(el2, "id", "profile");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "topics-section tab-pane scr-tab-top topics");
        dom.setAttribute(el2, "role", "tabpanel");
        dom.setAttribute(el2, "id", "topics");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "small-border");
        var el3 = dom.createElement("hr");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "votes-section tab-pane scr-tab-top votes");
        dom.setAttribute(el2, "role", "tabpanel");
        dom.setAttribute(el2, "id", "votes");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" END pagecomp-my-profile comp ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [7]), 1, 1);
        morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
        return morphs;
      },
      statements: [["inline", "user-profile", [], ["user", ["subexpr", "readonly", [["get", "attrs.user", ["loc", [null, [21, 32], [21, 42]]]]], [], ["loc", [null, [21, 22], [21, 43]]]], "currentAddress", ["subexpr", "readonly", [["get", "attrs.currentAddress", ["loc", [null, [22, 42], [22, 62]]]]], [], ["loc", [null, [22, 32], [22, 63]]]], "updateIsLoading", ["subexpr", "action", [["subexpr", "mut", [["get", "isLoading", ["loc", [null, [23, 46], [23, 55]]]]], [], ["loc", [null, [23, 41], [23, 56]]]]], [], ["loc", [null, [23, 33], [23, 57]]]]], ["loc", [null, [21, 2], [23, 59]]]], ["inline", "user-topics", [], ["title", "YOUR TOPICS", "desc", "", "buttonTitle", "Save", "enableTransition", false, "updateIsLoading", ["subexpr", "action", [["subexpr", "mut", [["get", "isLoading", ["loc", [null, [31, 45], [31, 54]]]]], [], ["loc", [null, [31, 40], [31, 55]]]]], [], ["loc", [null, [31, 32], [31, 56]]]], "transitionHandler", ["subexpr", "readonly", [["get", "attrs.transitionHandler", ["loc", [null, [32, 44], [32, 67]]]]], [], ["loc", [null, [32, 34], [32, 68]]]]], ["loc", [null, [27, 2], [32, 70]]]], ["inline", "user-votes", [], ["user", ["subexpr", "readonly", [["get", "attrs.user", ["loc", [null, [38, 30], [38, 40]]]]], [], ["loc", [null, [38, 20], [38, 41]]]], "votedBills", ["subexpr", "readonly", [["get", "attrs.votedBills", ["loc", [null, [39, 36], [39, 52]]]]], [], ["loc", [null, [39, 26], [39, 53]]]], "updateIsLoading", ["subexpr", "action", [["subexpr", "mut", [["get", "isLoading", ["loc", [null, [40, 44], [40, 53]]]]], [], ["loc", [null, [40, 39], [40, 54]]]]], [], ["loc", [null, [40, 31], [40, 55]]]]], ["loc", [null, [38, 2], [40, 57]]]], ["block", "liquid-if", [["get", "isLoading", ["loc", [null, [44, 13], [44, 22]]]]], [], 0, null, ["loc", [null, [44, 0], [55, 14]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});