define("votespotter-nxtgen/pods/components/bs-progressbar/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 37
          }
        },
        "moduleName": "votespotter-nxtgen/pods/components/bs-progressbar/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" START bs-progressbar component ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "bs-progressbar");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "progress");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "role", "progressbar");
        var el4 = dom.createTextNode("\n\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("%\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" END bs-progressbar component ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [3, 1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createAttrMorph(element0, 'aria-valuenow');
        morphs[2] = dom.createAttrMorph(element0, 'aria-valuemin');
        morphs[3] = dom.createAttrMorph(element0, 'aria-valuemax');
        morphs[4] = dom.createAttrMorph(element0, 'style');
        morphs[5] = dom.createMorphAt(element0, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["progress-bar ", ["get", "typeProgressbar", ["loc", [null, [22, 30], [22, 45]]]], " ", ["subexpr", "if", [["get", "isStriped", ["loc", [null, [22, 53], [22, 62]]]], "progress-bar-striped"], [], ["loc", [null, [22, 48], [22, 87]]]], " ", ["subexpr", "if", [["get", "isActive", ["loc", [null, [22, 93], [22, 101]]]], "active"], [], ["loc", [null, [22, 88], [22, 112]]]]]]], ["attribute", "aria-valuenow", ["get", "value", ["loc", [null, [24, 24], [24, 29]]]]], ["attribute", "aria-valuemin", ["get", "min", ["loc", [null, [25, 24], [25, 27]]]]], ["attribute", "aria-valuemax", ["get", "max", ["loc", [null, [26, 24], [26, 27]]]]], ["attribute", "style", ["get", "styleWidth", ["loc", [null, [27, 16], [27, 26]]]]], ["content", "valueText", ["loc", [null, [29, 6], [29, 19]]]]],
      locals: [],
      templates: []
    };
  })());
});