define('votespotter-nxtgen/initializers/in-app-livereload', ['exports', 'votespotter-nxtgen/config/environment', 'ember-cli-cordova/initializers/in-app-livereload'], function (exports, _votespotterNxtgenConfigEnvironment, _emberCliCordovaInitializersInAppLivereload) {

  var inAppReload = _emberCliCordovaInitializersInAppLivereload['default'].initialize;

  var initialize = function initialize(app) {
    if (typeof cordova === 'undefined' || _votespotterNxtgenConfigEnvironment['default'].environment !== 'development' || _votespotterNxtgenConfigEnvironment['default'].cordova && (!_votespotterNxtgenConfigEnvironment['default'].cordova.liveReload || !_votespotterNxtgenConfigEnvironment['default'].cordova.liveReload.enabled)) {
      return;
    }

    return inAppReload(app, _votespotterNxtgenConfigEnvironment['default']);
  };

  exports.initialize = initialize;
  exports['default'] = {
    name: 'cordova:in-app-livereload',
    initialize: initialize
  };
});
/* globals cordova */