define("votespotter-nxtgen/pods/feed/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.3",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 27,
              "column": 0
            }
          },
          "moduleName": "votespotter-nxtgen/pods/feed/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["content", "outlet", ["loc", [null, [26, 0], [26, 10]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 23
          }
        },
        "moduleName": "votespotter-nxtgen/pods/feed/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" START feed route ");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment(" END feed route ");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        return morphs;
      },
      statements: [["block", "pagecomp-feed", [], ["user", ["subexpr", "@mut", [["get", "user", ["loc", [null, [19, 22], [19, 26]]]]], [], []], "cQueryParam", ["subexpr", "readonly", [["get", "c", ["loc", [null, [20, 39], [20, 40]]]]], [], ["loc", [null, [20, 29], [20, 41]]]], "currentAddress", ["subexpr", "readonly", [["get", "currentAddress", ["loc", [null, [21, 42], [21, 56]]]]], [], ["loc", [null, [21, 32], [21, 57]]]], "bannerBill", ["subexpr", "readonly", [["get", "bannerBill", ["loc", [null, [22, 38], [22, 48]]]]], [], ["loc", [null, [22, 28], [22, 49]]]], "votedBills", ["subexpr", "readonly", [["get", "votedBills", ["loc", [null, [23, 38], [23, 48]]]]], [], ["loc", [null, [23, 28], [23, 49]]]], "unvotedBills", ["subexpr", "readonly", [["get", "unvotedBills", ["loc", [null, [24, 40], [24, 52]]]]], [], ["loc", [null, [24, 30], [24, 53]]]], "updateVotedBills", ["subexpr", "route-action", ["updateVotedBills"], [], ["loc", [null, [25, 34], [25, 67]]]]], 0, null, ["loc", [null, [18, 0], [27, 18]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});